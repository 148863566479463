<template>
  <article class="nhsd-m-card">
    <a href="https://github.com/NHS-digital-website/design-system" class="nhsd-a-box-link " target="_blank" rel="external"  aria-label="This project's source code in on GitHub" >
      <div class="nhsd-a-box nhsd-a-box--bg-light-grey">
        <div class="nhsd-m-card__content_container">
          <div class="nhsd-m-card__content-box">
            <div class="nhsd-m-card__tag-list">
              <span class="nhsd-a-tag nhsd-a-tag--bg-dark-grey">Open Source</span>
            </div>
            <p class="nhsd-t-heading-s">Want to contribute?</p>
            <p class="nhsd-t-body-s">The Design System's source code is hosted on GitHub.</p>
          </div>
          <div class="nhsd-m-card__button-box">
              <span class="nhsd-a-button">
                <span class="nhsd-a-button__label">GitHub</span>
              </span>
          </div>
        </div>
      </div>
    </a>
  </article>
</template>

<script>
export default {
  name: "ContributeCard"
}
</script>