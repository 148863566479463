<template>
  <article class="nhsd-m-card">
    <span class="nhsd-a-box-link " aria-label="About NHS Digital - More about what we do" >
      <div class="nhsd-a-box nhsd-a-box--bg-white nhsd-a-box--border-grey">
        <div class="nhsd-m-card__content_container">
          <div class="nhsd-m-card__content-box">
            <div class="nhsd-m-card__tag-list">
              <span class="nhsd-a-tag nhsd-a-tag--bg-dark-grey">Coming soon</span>
            </div>
            <h2 class="nhsd-t-heading-s">NPM</h2>
            <p class="nhsd-t-body-s nhsd-!t-padding-bottom-3">We are working on distributing the Design System via NPM. Stay tuned.</p>
          </div>
        </div>
      </div>
    </span>
  </article>
</template>

<script>
export default {
  name: "ContentDeliveryNetworkCard"
}
</script>