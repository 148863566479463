<template>
<div class="nhsd-m-emphasis-box nhsd-m-emphasis-box--wide">
  <div class="nhsd-a-box nhsd-a-box--border-blue">
    <div class="nhsd-m-emphasis-box__icon-box">
    </div>
    <div class="nhsd-m-emphasis-box__content-box">
        <p class="nhsd-t-body-s nhsd-t-word-break">This design system is for use on web properties which are branded for NHS Digital, usually focused on professionals.
            Teams building services for the public, or building services which are not NHS Digital branded should use the NHS design system 
            <a href="https://service-manual.nhs.uk/design-system" class="nhsd-a-link">here</a>
        </p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "HomeEmphasisBox"
}
</script>