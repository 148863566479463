<template>
  <div class="nhsd-m-phase-banner">
    <div class="nhsd-a-box nhsd-!t-bg-bright-blue-10-tint">
      <div class="nhsd-m-phase-banner__row">
        <div class="nhsd-m-phase-banner__left-col">
          <span class="nhsd-a-tag nhsd-a-tag--phase">BETA</span>
        </div>

        <div class="nhsd-m-phase-banner__right-col">
          This is a new service. Your 
          <a class="nhsd-a-link" href="mailto:websitedevelopment@nhs.net">feedback</a>
          will help us improve it.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePhaseBanner"
}
</script>